body {
  --scrollbar-width: 7px;
  --scrollbar-height: 7px;

  --legend-text-color: var(--md-sys-color-on-primary-container) !important;

  --ox-prompt-title-text-align: center;
  --ox-prompt-content-text-align: left;

  --grid-record-disabled-opacity: 0.7;
}
