/* material web color roles : https://m3.material.io/styles/color/roles#5fdf196d-1e21-4d03-ae63-e802d61ad5ee */

.light {
  --md-sys-color-primary: rgb(56 162 91); /* for header bar bg, auth UI bg, grist header top border, grist header th color, menu icon, menu active */
  --md-sys-color-surface-tint: rgb(63 191 69);
  --md-sys-color-on-primary: rgba(255,255,255,.9); /* for header bar font */
  --md-sys-color-primary-container: rgb(227 245 230);
  --md-sys-color-on-primary-container: rgb(50 82 52); /* for default text bg */

  --md-sys-color-secondary: rgb(38 59 80);
  --md-sys-color-on-secondary: rgb(255 255 255);
  --md-sys-color-secondary-container: rgb(214 222 225);
  --md-sys-color-on-secondary-container: rgb(63 88 113);

  --md-sys-color-tertiary: rgb(46 121 190);
  --md-sys-color-on-tertiary: rgb(255 255 255);
  --md-sys-color-tertiary-container: rgb(215 231 241);
  --md-sys-color-on-tertiary-container: rgb(50 66 82);

  --md-sys-color-error: rgb(225 87 87);
  --md-sys-color-on-error: rgb(255 255 255);
  --md-sys-color-error-container: rgb(242 226 226);
  --md-sys-color-on-error-container: rgb(225 87 87);

  --md-sys-color-background: rgb(245 249 245); /* for content bg */
  --md-sys-color-on-background: var(--md-sys-color-on-primary-container);
  --md-sys-color-surface: rgb(232 244 232);
  --md-sys-color-on-surface: var(--md-sys-color-on-primary-container);
  --md-sys-color-surface-variant: rgb(205 229 204); /* for more pannel bg */
  --md-sys-color-on-surface-variant: var(--md-sys-color-on-primary-container);

  --md-sys-color-outline: rgba(0,0,0,.25);
  --md-sys-color-outline-variant: rgba(0,0,0,.15);
  --md-sys-color-shadow: rgb(0 0 0);
  --md-sys-color-scrim: rgb(0 0 0);
  --md-sys-color-inverse-surface: rgb(30 101 25); 
  --md-sys-color-inverse-on-surface: rgba(242,253,239,.9);
  --md-sys-color-inverse-primary: rgba(63, 191, 69,.2); /* for grist edit cell bg */

  --md-sys-color-primary-fixed: rgb(74 197 92);
  --md-sys-color-on-primary-fixed: rgb(255 255 255);
  --md-sys-color-primary-fixed-dim: rgb(65 166 86);
  --md-sys-color-on-primary-fixed-variant: rgba(255, 255, 255,.8);
  --md-sys-color-secondary-fixed: rgb(59 110 160);
  --md-sys-color-on-secondary-fixed: rgb(211 233 254);
  --md-sys-color-secondary-fixed-dim: rgb(47 88 128);
  --md-sys-color-on-secondary-fixed-variant: rgba(211, 233, 254,.8);
  --md-sys-color-tertiary-fixed: rgb(106 183 254);
  --md-sys-color-on-tertiary-fixed: rgb(255 255 255);
  --md-sys-color-tertiary-fixed-dim: rgb(85 146 203);
  --md-sys-color-on-tertiary-fixed-variant: rgba(255, 255, 255,.8);

  --md-sys-color-surface-dim: rgb(220 225 219);
  --md-sys-color-surface-bright: rgb(234 235 234);
  --md-sys-color-surface-container-lowest: rgb(255 255 255);
  --md-sys-color-surface-container-low: rgb(236 242 235);
  --md-sys-color-surface-container: rgb(228 234 227);
  --md-sys-color-surface-container-high: rgb(231 234 231);
  --md-sys-color-surface-container-highest: rgb(225 228 224);
}
