/* material web color roles : https://m3.material.io/styles/color/roles#5fdf196d-1e21-4d03-ae63-e802d61ad5ee */

.dark {
  --md-sys-color-primary: rgb(75 197 58); /* for header bar bg*/
  --md-sys-color-surface-tint: rgb(92 223 62);
  --md-sys-color-on-primary: rgb(7 46 14); /* for header bar font */
  --md-sys-color-primary-container: rgb(38 71 33);
  --md-sys-color-on-primary-container: rgb(255 255 255); 

  --md-sys-color-secondary: rgb(179 201 227);
  --md-sys-color-on-secondary: rgb(28 50 71);
  --md-sys-color-secondary-container: rgb(41 44 48);
  --md-sys-color-on-secondary-container: rgb(197 219 246);

  --md-sys-color-tertiary: rgb(77 168 252);
  --md-sys-color-on-tertiary: rgb(0 20 30);
  --md-sys-color-tertiary-container: rgb(26 40 52);
  --md-sys-color-on-tertiary-container: rgb(255 255 255);

  --md-sys-color-error: rgb(248 90 87);
  --md-sys-color-on-error: rgb(255 234 192);
  --md-sys-color-error-container: rgb(51 28 27);
  --md-sys-color-on-error-container: rgb(255 255 255);

  --md-sys-color-background: rgb(17 35 18); /* for content bg */
  --md-sys-color-on-background: rgb(222 227 222);
  --md-sys-color-surface: rgb(31 43 31);
  --md-sys-color-on-surface: rgb(211 225 211);
  --md-sys-color-surface-variant: rgb(62 73 61); /* for more pannel bg */
  --md-sys-color-on-surface-variant: rgb(218 245 217);

  --md-sys-color-outline: rgba(255,255,255,.25);
  --md-sys-color-outline-variant: rgba(255,255,255,.15);
  --md-sys-color-shadow: rgb(0 0 0);
  --md-sys-color-scrim: rgb(0 0 0);
  --md-sys-color-inverse-surface: rgb(204 234 204);
  --md-sys-color-inverse-on-surface: rgb(48 62 49);
  --md-sys-color-inverse-primary: rgb(60 192 63); /* for header bar bg */
  
  --md-sys-color-primary-fixed: rgb(74 197 92);
  --md-sys-color-on-primary-fixed: rgb(255 255 255);
  --md-sys-color-primary-fixed-dim: rgb(65 166 86);
  --md-sys-color-on-primary-fixed-variant: rgba(255, 255, 255,.8);
  --md-sys-color-secondary-fixed: rgb(59 110 160);
  --md-sys-color-on-secondary-fixed: rgb(211 233 254);
  --md-sys-color-secondary-fixed-dim: rgb(47 88 128);
  --md-sys-color-on-secondary-fixed-variant: rgba(211, 233, 254,.8);
  --md-sys-color-tertiary-fixed: rgb(106 183 254);
  --md-sys-color-on-tertiary-fixed: rgb(255 255 255);
  --md-sys-color-tertiary-fixed-d4im: rgb(85 146 203);
  --md-sys-color-on-tertiary-fixed-variant: rgba(255, 255, 255,.8);

  --md-sys-color-surface-dim: rgb(20 27 20);
  --md-sys-color-surface-bright: rgb(53 58 53);
  --md-sys-color-surface-container-lowest: rgb(18 27 17);
  --md-sys-color-surface-container-low: rgb(28 36 28);
  --md-sys-color-surface-container: rgb(30 38 31);
  --md-sys-color-surface-container-high: rgb(38 46 39);
  --md-sys-color-surface-container-highest: rgb(50 58 51);
}
